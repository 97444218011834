<template>
  <div class="row">
    <div class="col-12">
      <!-- 평가대상 목록 - 작업/작업단계/유해위험요인 -->
      <c-table
        ref="table"
        title="LBL0001097"
        :columns="grid.columns"
        :gridHeight="grid.height"
        :data="process.sops"
        :merge="grid.merge"
        :columnSetting="false"
        :expandAll="false"
        :isFullScreen="false"
        :usePaging="false"
        :hideBottom="true"
        :filtering="false"
        :editable="editable&&!disabled&&isSelect"
        selection="multiple"
        rowKey="ramJsaAssessScenarioId"
        @innerBtnClicked="innerBtnClicked"
      >
        <!-- 버튼 영역 -->
        <template slot="table-button">
          <!-- 범례 -->
          <q-chip 
            outline square clickable 
            size="md"
            color="light-green-8" 
            text-color="white"
            icon="contact_support"
            :label="$comm.getLangLabel('LBLLEGEND')"
            style="height:26px;margin-right:2px !important;"
            class="q-ml-sm">
            <q-popup-proxy>
              <q-banner>
              <q-chip color="green" outline square/>
                <!-- 작업단계추가 -->
                {{$label('LBL0001098')}}
              </q-banner>
              <q-separator inset />
              <q-banner>
              <q-chip color="lime" outline square/>
                <!-- 유해위험요인추가 -->
                {{$label('LBL0001099')}}
              </q-banner>
            </q-popup-proxy>
          </q-chip>
          <q-btn-group outline >
            <c-btn v-show="editable&&!disabled&&isSelect" label="LBLADD" icon="add" @btnClicked="addJob" />
            <!-- SOP 추가 -->
            <c-btn v-show="editable&&!disabled&&isSelect&&checkProcessCd" label="LBL0001100" icon="add" @btnClicked="openSop" />
            <c-btn v-show="editable&&!disabled&&isSelect" label="LBLREMOVE" icon="remove" @btnClicked="removeSop" />
            <c-btn 
              v-show="false" 
              :isSubmit="isSave"
              :url="saveUrl"
              :param="sopScenarioSave"
              mappingType="PUT"
              label="LBLSAVE" 
              icon="save"
              @beforeAction="saveSop"
              @btnCallback="saveSopCallback" />
          </q-btn-group>
        </template>
        <template v-slot:customArea="{ props, col }">
          <template v-if="col.name==='customCol'">
            <q-chip
              color="amber"
              outline square
              :clickable="true"
              text-color="white"
              style="margin-bottom:4px !important;"
              @click.stop="() => { rowClick(props.row, props.pageIndex) }">
              <!-- 수정 -->
              {{$label('LBLUPDATE')}}
            </q-chip>
          </template>
        </template>
      </c-table>
    </div>
    <q-dialog v-model="sopDialog" persistent>
      <q-card style="min-width: 350px">
        <q-form ref="editForm">
          <!-- 작업/작업단계/유해위험요인 추가 -->
          <c-card title="LBL0001101" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn 
                  v-show="editable&&!disabled&&isSelect&&isRowClick" 
                  :disabled="disabledSop" 
                  label="LBLSAVE" 
                  icon="save" 
                  @btnClicked="accepts"/>
                <c-btn 
                  label='LBLCLOSE' 
                  icon="cancel" 
                  @btnClicked="closeDialog"
                />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-12">
                <c-text
                  :editable="editable"
                  :disabled="true"
                  label="LBLPROCESS"
                  name="processName"
                  v-model="sop.processName">
                </c-text>
              </div>
              <div class="col-12">
                <c-text
                  :editable="editable"
                  :disabled="disabledSop || disabled1 || !isRowClick"
                  :required="true"
                  label="LBLJOB"
                  name="jobName"
                  v-model="sop.jobName"
                  @dataChange="jobChange">
                </c-text>
              </div>
              <div class="col-12">
                <!-- 작업단계순번 -->
                <c-text
                  :editable="editable"
                  :disabled="disabledSop || disabled2 || !isRowClick"
                  :required="true"
                  label="LBL0001104"
                  name="jobStepNo"
                  v-model="sop.jobStepNo"
                  @dataChange="jobStepChange">
                </c-text>
              </div>
              <div class="col-12">
                <!-- 작업단계 -->
                <c-text
                  :editable="editable"
                  :disabled="disabledSop || disabled2 || !isRowClick"
                  :required="true"
                  label="LBL0001103"
                  name="jobStepName"
                  v-model="sop.jobStepName"
                  @dataChange="jobStepChange">
                </c-text>
              </div>
              <div class="col-12">
                <!-- 작업단계설명 -->
                <c-text
                  :editable="editable"
                  :disabled="disabledSop || disabled2 || !isRowClick"
                  label="LBL0001102"
                  name="jobStepDesc"
                  v-model="sop.jobStepDesc"
                  @dataChange="jobStepChange">
                </c-text>
              </div>
              <div class="col-12">
                <!-- 유해위험요인 -->
                <c-text
                  :afterIcon="editable&&!disabledSop&&!disabled3&&isRowClick ? [
                    { name: 'search', click: true, callbackName: 'searchRiskHazard', color: 'teal' },
                    { name: 'close', click: true, callbackName: 'removeRiskHazard', color: 'red' }
                  ] : null"
                  :editable="editable"
                  :disabled="disabledSop || disabled3 || !isRowClick"
                  :required="true"
                  label="LBL0001050"
                  name="riskHazardNameClass"
                  v-model="sop.riskHazardNameClass"
                  @searchRiskHazard="searchRiskHazard"
                  @removeRiskHazard="removeRiskHazard"
                  @dataChange="riskHazardChagne">
                </c-text>
              </div>
            </template>
          </c-card>
        </q-form>
      </q-card>
    </q-dialog>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';

export default {
  name: 'jsa-target-process',

  props: {
    process: {
      type: Object,
      default: () => ({
        ramRiskAssessmentPlanId: '',  // 평가 일련번호
        processCd: '',  // 단위공정 코드
        processName: '',  // 단위공정명
        ramProcessAssessStepCd: '',  // 평가진행상태
        processExplainFeature: '',  // 공정 특징 설명
        assessDeptCd: '',  // 평가 부서 코드
        assessUserId: '',  // 평가 담당자
        regUserId: '',  // 작성자 ID
        chgUserId: '',  // 수정자 ID
        equipments: [],
        sops: [],
      }),
    },
    disabled: {
      type: Boolean,
      default: function() {
        return false;
      }
    },
    updateBtnData: {
      type: Object,
      default: () => ({
        flag: false,
        research: '',
      }),
    },
  },
  data() {
    return {
      grid: {
        merge: [
          { index: 0, colName: 'processCd' },
          { index: 1, colName: 'groupMdmSopId' },
          { index: 2, colName: 'groupJobStepId' },
        ],
        columns: [
          {
            name: 'processName',
            field: 'processName',
            label: 'LBLPROCESS',
            align: 'center',
            style: 'width:150px',
            sortable: false,
          },
          {
            name: 'jobName',
            field: 'jobName',
            label: 'LBLJOB',
            align: 'center',
            style: 'width:160px',
            sortable: false,
            innerBtn: true,
            btns: [
              { label: '', icon: 'add', color: 'green' }
            ]
          },
          {
            name: 'jobStepName',
            field: 'jobStepName',
            description: 'jobStepDesc',
            // 작업단계
            label: 'LBL0001103',
            align: 'center',
            style: 'width:200px',
            sortable: false,
            innerBtn: true,
            btns: [
              { label: '', icon: 'add', color: 'lime' }
            ]
          },
          {
            name: 'riskHazardNameClass',
            field: 'riskHazardNameClass',
            // 분류 - 유해위험요인
            label: 'LBL0001105',
            style: 'width:250px',
            align: 'left',
            sortable: false,
          },
          {
            name: 'customCol',
            field: 'customCol',
            label: 'LBLDETAIL',
            align: 'center',
            style: 'width:40px',
            type: 'custom',
            sortable: false
          },
        ],
        data: [],
        height: '550px'
      },
      sop: {
        ramJsaAssessJobId: '',  // 안전작업 일련번호
        ramRiskAssessmentPlanId: '',  // 평가 일련번호
        processCd: '',  // 단위공정 코드
        mdmSopId: '',  // 안전작업 표준 일련번호_sop 작업이 아닌경우는 "tmp_001"과 같이 생성
        groupMdmSopId: '',  // 안전작업 표준 일련번호_sop 작업이 아닌경우는 "tmp_001"과 같이 생성
        sopFlag: 'N',  // sop 여부_sop에서 작업을 불러오는 경우
        jobName: '',  // 안전작업 명
        groupJobStepId: '',  // 작업단계일련번호
        jobStepId: '',  // 작업단계일련번호
        jobStepNo: '',  // 작업단계 번호
        jobStepName: '',  // 작업단계 명
        jobStepDesc: '',  // 작업단계 설명
        ramRiskHazardId: '',  // 유해위험요인 코드
        riskHazardName: '',  // 유해위험요인 명
        riskHazardNameClass: '',  // 유해위험요인 명
        regUserId: '',  // 작성자 ID
        chgUserId: '',  // 수정자 ID
      },
      sopScenarioSave: {
        ramRiskAssessmentPlanId: '',
        processCd: '',
        sops: [],
        scenarios: [],
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '50%',
        param: {},
        closeCallback: null,
      },
      disabled1: false,
      disabled2: false,
      disabled3: false,
      
      isSameJobName: false,
      isSameJobStepName: false,
      editable: true,
      listUrl: '',
      saveUrl: '',
      deleteUrl: '',
      isSave: false,
      rowIndex: 0,
      sopDialog: false,
    };
  },
  computed: {
    isSelect() {
      return this.process 
        && this.process.processCd
        && this.process.ramProcessAssessStepCd !== 'RPA0000005' ? true : false
    },
    disabledSop() {
      return this.disabled || !this.isSelect; // || this.sop.sopFlag === 'Y'
    },
    checkProcessCd() {
      return this.process.processCd.indexOf('WTE') === -1
    },
    isRowClick() {
      return Boolean(this.sop.ramJsaAssessJobId)
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.ram.assessSop.list.url
      this.saveUrl = transactionConfig.ram.assessSop.save.url
      this.deleteUrl = transactionConfig.ram.assessSop.delete.url
      // code setting
      // list setting
    },
    getSops() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        ramRiskAssessmentPlanId: this.process.ramRiskAssessmentPlanId,
        processCd: this.process.processCd
      }
      this.$http.request((_result) => {
        this.process.sops = _result.data
      },);
    },
    openSop() {      
      this.popupOptions.title = 'LBL0001106'; // SOP 검색
      this.popupOptions.param = {
        type: 'multiple',
        searchType: '3', //1: 작업조회, 2:작업단계, 3:작업단계별 유해위험요인, 4:작업단계별 유해위험요인별 원인/결과
        subProcessCd: this.process.processCd
      };
      this.popupOptions.target = () => import(`${'@/pages/common/process/sopPop.vue'}`);
      this.popupOptions.width = '70%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeSopPopup;
    },
    closeSopPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        /**
         * 관리되는 SOP를 선택했을때 고려할 점
         *  1. mdmSopId 기준으로 이전에 등록된 데이터가 존재할 수 있음
         *  2. 존재하는 SOP의 jobName이 변경되었을 가능성이 존재
         *  3. 저장할 때에 같은 mdmSopId를 가지고 있다면 최신으로 업데이트하는 로직 필요
         */
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.process.sops, { 
                mdmSopId: item.mdmSopId,
                jobStepId: item.jobStepId,
                ramRiskHazardId: item.mdmHazardFactorsId,
              }) === -1) {
            let spliceIdx = this.$_.findIndex(this.process.sops, { mdmSopId: item.mdmSopId, });
            if (spliceIdx > -1) {
              let spliceIdx2 = this.$_.findIndex(this.process.sops, { mdmSopId: item.mdmSopId, jobStepId: item.jobStepId, });
              spliceIdx = spliceIdx2 > -1 ? spliceIdx2 : spliceIdx
            } else {
              spliceIdx = 0;
            }
            this.$_.forEach(this.process.sops, sop => {
              if (sop.mdmSopId === item.mdmSopId && sop.jobName !== item.jobName) {
                this.$set(sop, 'jobName', item.jobName);
                if (sop.jobEditFlag !== 'C') {
                  sop.jobEditFlag = 'U';
                }
              }

              if (sop.jobStepId === item.jobStepId 
                && (sop.jobStepNo !== item.jobStepNo || sop.jobStepName !== item.jobStepName || sop.jobStepDesc !== item.jobStepDesc)) {
                this.$set(sop, 'jobStepNo', item.jobStepNo);
                this.$set(sop, 'jobStepName', item.jobStepName);
                this.$set(sop, 'jobStepDesc', item.jobStepDesc);
                if (sop.scenarioEditFlag !== 'C') {
                  sop.scenarioEditFlag = 'U';
                }
              }
            })
            this.process.sops.splice(spliceIdx, 0, {
              ramJsaAssessScenarioId: uid(),
              ramRiskAssessmentPlanId: this.process.ramRiskAssessmentPlanId,
              processCd: this.process.processCd,
              processName: this.process.processName,
              ramJsaAssessJobId: '',
              ramTechniqueCd: 'RT00000015',
              groupMdmSopId: this.process.processCd + '/' + item.mdmSopId,
              mdmSopId: item.mdmSopId,
              sopFlag: 'Y',
              jobName: item.sopName,
              groupJobStepId: this.process.processCd + '/' + item.mdmSopId + '/' + item.jobStepId,
              jobStepId: item.jobStepId,
              jobStepNo: item.jobStepNo,
              jobStepName: item.jobStepName,
              jobStepDesc: item.jobStepDesc,
              ramRiskHazardClassCd: item.ramRiskHazardClassCd,
              relationLaw: item.relevantLegalStandards,
              ramRiskHazardId: item.mdmHazardFactorsId,
              riskHazardName: item.riskHazardName,
              riskHazardNameClass: item.ramRiskHazardClassName + ' - ' + item.riskHazardName,
              regUserId: this.$store.getters.user.userId,
              jobEditFlag: 'C',
              scenarioEditFlag: 'C',
            })
          }
        })
        this.saveSop();
      }
    },
    rowClick(row, index) {
      /**
       * 이후에 해당 정보를 클릭 한 후 수정하려고 할 수 있도록 열것임
       * 다만 해당 마다 존재하는 키값이 temp로 따진 키가 아닐 경우에만 한에서 열어줄것임
       * 작업seq, 작업단계seq, 유해위험요인seq
       */
      this.rowIndex = index;
      if (!row.mdmSopId) {
        this.disabled1 = false;
        this.disabled2 = false;
        this.disabled3 = false;
      } else if (!row.jobStepId) {
        this.disabled1 = true;
        this.disabled2 = false;
        this.disabled3 = false;
      } else {
        this.disabled1 = true;
        this.disabled2 = true;
        this.disabled3 = false;
      }
      this.$_.extend(this.sop, row);
      this.sopDialog = true;
    },
    addJob() {
      this.rowIndex = 0;
      this.disabled1 = false;
      this.disabled2 = false;
      this.disabled3 = false;
      let groupMdmSopId = this.process.processCd + '/' + uid();
      let sop = { 
        ramJsaAssessScenarioId: uid(),
        ramRiskAssessmentPlanId: this.process.ramRiskAssessmentPlanId,
        processCd: this.process.processCd,  // 단위공정 코드
        processName: this.process.processName,
        ramJsaAssessJobId: '',
        ramTechniqueCd: 'RT00000015',
        groupMdmSopId: groupMdmSopId,
        mdmSopId: '',
        sopFlag: 'N',
        jobName: '',
        groupJobStepId: groupMdmSopId + '/' + uid(),
        jobStepId: '',
        jobStepNo: '',
        jobStepName: '',
        jobStepDesc: '',
        ramRiskHazardId: '',
        riskHazardName: '',
        riskHazardNameClass: '',
        regUserId: this.$store.getters.user.userId,
        jobEditFlag: 'C',
        scenarioEditFlag: 'C',
      }
      this.rowClick(sop, this.rowIndex)
    },
    innerBtnClicked(col, props) {
      this.rowIndex = props.rowIndex;
      if (col.name === 'jobName') {
        this.addSopDetail(props)
      } else if (col.name === 'jobStepName') {
        this.addRiskHazard(props)
      }
    },
    addSopDetail(props) {
      this.disabled1 = true;
      this.disabled2 = false;
      this.disabled3 = false;
      let sop = { 
        ramJsaAssessScenarioId: uid(),
        ramRiskAssessmentPlanId: props.row.ramRiskAssessmentPlanId,
        processCd: props.row.processCd,
        processName: props.row.processName,
        ramJsaAssessJobId: props.row.ramJsaAssessJobId,
        ramTechniqueCd: props.row.ramTechniqueCd,
        groupMdmSopId: props.row.groupMdmSopId,
        mdmSopId: props.row.mdmSopId,
        sopFlag: props.row.mdmSopId ? 'Y' : 'N',
        jobName: props.row.jobName,
        groupJobStepId: props.row.groupMdmSopId + '/' + uid(),
        jobStepId: '',
        jobStepNo: '',
        jobStepName: '',
        jobStepDesc: '',
        ramRiskHazardId: '',
        riskHazardName: '',
        riskHazardNameClass: '',
        regUserId: this.$store.getters.user.userId,
        jobEditFlag: '',
        scenarioEditFlag: 'C',
      }
      this.rowClick(sop, this.rowIndex)
    },
    addRiskHazard(props) {
      this.disabled1 = true;
      this.disabled2 = true;
      this.disabled3 = false;
      let sop = { 
        ramJsaAssessScenarioId: uid(),
        ramRiskAssessmentPlanId: props.row.ramRiskAssessmentPlanId,
        processCd: props.row.processCd,
        processName: props.row.processName,
        ramJsaAssessJobId: props.row.ramJsaAssessJobId,
        ramTechniqueCd: props.row.ramTechniqueCd,
        groupMdmSopId: props.row.groupMdmSopId,
        mdmSopId: props.row.mdmSopId,
        sopFlag: props.row.mdmSopId ? 'Y' : 'N',
        jobName: props.row.jobName,
        groupJobStepId: props.row.groupJobStepId,
        jobStepId: props.row.jobStepId,
        jobStepNo: props.row.jobStepNo,
        jobStepName: props.row.jobStepName,
        jobStepDesc: props.row.jobStepDesc,
        ramRiskHazardId: '',
        riskHazardName: '',
        riskHazardNameClass: '',
        regUserId: this.$store.getters.user.userId,
        jobEditFlag: '',
        scenarioEditFlag: 'C',
      }
      this.rowClick(sop, this.rowIndex)
    },
    searchRiskHazard() {
      this.popupOptions.title = 'LBL0001107'; // 유해위험요인 검색
      this.popupOptions.param = {
        type: 'single'
      };
      this.popupOptions.target = () => import(`${'@/pages/ram/base/riskHazardPop.vue'}`);
      this.popupOptions.width = '60%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeRiskHazardPopup;
    },
    closeRiskHazardPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        if (this.$_.findIndex(this.$_.filter(this.process.sops, { 
          groupMdmSopId: this.sop.groupMdmSopId,
          groupJobStepId: this.sop.groupJobStepId,
         }), { ramRiskHazardId: data[0].ramRiskHazardId }) === -1) {
          this.sop.ramRiskHazardId = data[0].ramRiskHazardId
          this.sop.riskHazardName = data[0].riskHazardName
          this.sop.riskHazardNameClass = data[0].ramRiskHazardClassName + ' - ' + data[0].riskHazardName
        } else {
          window.getApp.$emit('ALERT', {
            title: 'LBLGUIDE', // 안내
            // 해당 작업 - 작업단계에 존재하는 유해위험요인입니다.
            message: 'MSG0000388',
            type: 'warning', // success / info / warning / error
          });
        }
      }
    },
    removeRiskHazard() {
      this.sop.ramRiskHazardId = null
      this.sop.riskHazardName = null
      this.sop.riskHazardNameClass = null
    },
    jobChange() {
      if (this.$_.findIndex(this.process.sops, { jobName: this.sop.jobName, }) > -1) {
        this.isSameJobName = true;
      } else {
        this.isSameJobName = false;
        if (this.sop.jobEditFlag !== 'C') {
          this.sop.jobEditFlag = 'U'
        }
      }
    },
    jobStepChange() {
      let idx = this.$_.findIndex(this.process.sops, { 
        groupMdmSopId: this.sop.groupMdmSopId,
        jobStepName: this.sop.jobStepName,
      });
      if (idx > -1) {
        this.isSameJobStepName = true;
      } else {
        this.isSameJobStepName = false;
        if (this.sop.scenarioEditFlag !== 'C') {
          this.sop.scenarioEditFlag = 'U'
        }
      }
    },
    riskHazardChagne(props) {
      /**
       * 직적 입력하는 순간 유해위험요인의 id는 null처리
       */
      if (props && props.row) {
        this.$set(props.row, 'ramRiskHazardId', null)
      } else {
        this.sop.ramRiskHazardId = null
      }
      this.sop.riskHazardName = this.sop.riskHazardNameClass
      if (this.sop.scenarioEditFlag !== 'C') {
        this.sop.scenarioEditFlag = 'U'
      }
    },
    accepts() {
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          if (this.isSameJobName) {
            window.getApp.$emit('ALERT', {
              title: 'LBLGUIDE', // 안내
              message: 'MSG0000389', // 동일한 작업명이 존재합니다.
              type: 'warning', // success / info / warning / error
            });
            this.isSameJobName = false;
            this.isSameJobStepName = false;
            return;
          }
          if (this.isSameJobStepName) {
            window.getApp.$emit('ALERT', {
              title: 'LBLGUIDE', // 안내
              message: 'MSG0000390', // 동일한 작업단계명이 존재합니다.
              type: 'warning', // success / info / warning / error
            });
            this.isSameJobName = false;
            this.isSameJobStepName = false;
            return;
          }

          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE', // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.process.sops.splice(this.rowIndex, 0, this.$_.clone(this.sop));

              // 초기화 & dialog close
              Object.assign(this.$data.sop, this.$options.data().sop);
              this.sopDialog = false;

              this.saveSop();
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    closeDialog() {
      this.isSameJobName = false;
      this.isSameJobStepName = false;
      Object.assign(this.$data.sop, this.$options.data().sop);
      this.sopDialog = false;
    },
    saveSop() {
      this.$_.forEach(this.process.sops, sop => {
        sop.chgUserId = this.$store.getters.user.userId
      });
      this.sopScenarioSave.ramRiskAssessmentPlanId = this.process.ramRiskAssessmentPlanId
      this.sopScenarioSave.processCd = this.process.processCd
      this.sopScenarioSave.sops = [];
      this.sopScenarioSave.scenarios = [];
      /**
       * 작업별로 모음
       */
      this.$_.forEach(this.$_.filter(this.process.sops, item => {
        return item.jobEditFlag === 'C' || item.jobEditFlag === 'U'
      }), sop => {
        if (this.$_.findIndex(this.sopScenarioSave.sops, { jobName: sop.jobName }) === -1) {
          this.sopScenarioSave.sops.push(sop);
        }
      });
      this.sopScenarioSave.scenarios = this.$_.map(this.process.sops, sop => {
        return this.$_.extend(sop, {
          editFlag: sop.scenarioEditFlag
        })
      });

      this.isSave = !this.isSave
    },
    saveSopCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$emit('saveProcess', this.process.processCd)
      this.getSops();
      Object.assign(this.$data.sop, this.$options.data().sop);
      /**
       * 계획정보수정
       *  변경사항을 적용하기 위함
       */
      if (this.updateBtnData.flag) {
        this.updateBtnData.research = uid();
      }
    },
    removeSop() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        let message = 'MSGREMOVE'; // 삭제하시겠습니까?
        if (this.updateBtnData.flag) {
          // 현재 평가실행 중인 유해위험요인입니다.\n관련되어 있는 정보 및 개선사항, 위험등록부가 같이 삭제됩니다.\n진행하시겠습니까?
          message = 'MSG0000387'
        }
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: message,
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.deleteUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData),
            };
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              // this.$emit('saveProcess', this.process.processCd)
              // this.getSops();
          
              this.$_.forEach(selectData, item => {
                this.process.sops = this.$_.reject(this.process.sops, item);
              })

              this.$refs['table'].$refs['compo-table'].clearSelection();

              /**
               * 계획정보수정
               *  변경사항을 적용하기 위함
               */
              if (this.updateBtnData.flag) {
                this.updateBtnData.research = uid();
              }
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
  }
};
</script>
